import { navigate, PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useCart } from 'manage-tritag/hooks/useCart'
import RegistrationReview from 'manage-tritag/components/register/review/review'
import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'

const RegisterPlayerReviewPage = (props: PageProps) => {
  const cartType = 'player'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  const { cart, isCartLoading } = useCart({ type: cartType })

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.competition) {
      navigate(`/register/player/venue`)
    }
  }, [cart?.data?.competition, isCartLoading])

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.participantDetails) {
      navigate(`/register/player/details`)
    }
  }, [cart?.data?.participantDetails, isCartLoading])

  const onSuccess = () => {
    navigate('/register/player/success')
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <RegistrationReview onSuccess={onSuccess} cartType={cartType} />
    </>
  )
}

export default RegisterPlayerReviewPage
